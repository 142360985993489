import React, { useState, useEffect } from "react";
import "./ServicePage.css";
import Header from './header'; 
import Footer from './Footer'; 

const ServicePage = () => {
  const [expandedService, setExpandedService] = useState(null);
  const [timer, setTimer] = useState(null);
  const [subject, setSubject] = useState('');  // State to hold the subject value
  const [isSubjectEditable, setIsSubjectEditable] = useState(true);  // To control if the subject is editable

  const servicesExtend = [
    {
        id: "business-incorporation",
        name: "Business Incorporation",
        description: "We provide strategic guidance in corporate structuring, ensuring that your business registration, licensing, and permitting processes are seamless and compliant with all regulatory requirements. Our expertise encompasses both local and international incorporation processes, catering to startups, SMEs, and multinational entities.\n\nThis service is ideal for entrepreneurs, business owners, and organizations looking to establish a solid legal foundation for their operations while minimizing potential risks associated with non-compliance."
    },
    {
        id: "ip-protection",
        name: "IP Protection",
        description: "Our intellectual property services are designed to help you safeguard your creative and innovative assets. We offer comprehensive support in securing copyrights, trademarks, patents, and trade secrets, ensuring that your intellectual property is legally protected and commercially optimized.\n\nThis service is tailored for innovators, artists, technology developers, and businesses aiming to monetize their intellectual property while preventing unauthorized use or infringement."
    },
    {
        id: "tech-compliance",
        name: "Tech Compliance",
        description: "Stay ahead of regulatory requirements with our specialized technology compliance services. We assist in aligning your tech development processes with legal standards, covering areas such as data security, software licensing, and emerging technology frameworks.\n\nIdeal for tech startups, developers, and established IT firms, this service ensures that your innovations remain secure and legally compliant in a rapidly evolving industry."
    },
    {
        id: "international-expansion",
        name: "International Expansion",
        description: "Expand your business globally with confidence through our expert legal support. We navigate complex international regulations, helping you establish operations in new markets while addressing compliance, taxation, and contractual obligations.\n\nThis service is essential for companies looking to enter new regions, foreign investors, and global entrepreneurs seeking to overcome legal and logistical challenges in international trade."
    },
    {
        id: "contracts-partnerships",
        name: "Contracts & Partnerships",
        description: "Ensure that your agreements are robust and legally sound with our contract review and negotiation services. We help draft and refine partnership agreements, employment contracts, and service-level agreements tailored to your unique business needs.\n\nThis service is beneficial for businesses, freelancers, and collaborative ventures aiming to establish clear, enforceable terms that protect all parties involved."
    },
    {
        id: "data-privacy",
        name: "Data Privacy",
        description: "Protect your organization from data breaches and regulatory penalties with our data privacy compliance services. We offer expert guidance on adhering to data protection laws, such as GDPR and CCPA, and managing cross-border data transfers securely.\n\nThis service is critical for businesses handling sensitive customer data, tech companies, and global organizations aiming to maintain trust and legal compliance in their data practices."
    },
    {
        id: "investments-due-diligence",
        name: "Investments & Due Diligence",
        description: "Make informed investment decisions with our due diligence and legal advisory services. We assess the financial, legal, and operational risks associated with potential investments, ensuring that your interests are protected.\n\nThis service is designed for investors, venture capitalists, and businesses seeking secure and favorable investment structures with minimized risks."
    },
    {
        id: "mergers-acquisitions",
        name: "Mergers & Acquisitions",
        description: "Our mergers and acquisitions services provide comprehensive support in navigating the complexities of business consolidation. From due diligence to contract negotiation, we ensure that your transactions are executed efficiently and align with your strategic goals.\n\nThis service caters to corporate entities, investors, and legal professionals involved in acquisitions, mergers, or restructuring efforts requiring expert legal oversight."
    }
];


  // Auto-close the card after 7 seconds of inactivity
  const closeCardAfterTimeout = () => {
    if (timer) clearTimeout(timer); // Clear any existing timeout
    const newTimer = setTimeout(() => setExpandedService(null), 7000);
    setTimer(newTimer);
  };

  const handleServiceToggle = (serviceId) => {
    setExpandedService(prevService => (prevService === serviceId ? null : serviceId));
    closeCardAfterTimeout();  // Restart the timeout every time a service is toggled
  };

  const handleButtonClick = (serviceName, buttonName) => {
    // Set the subject field with the service name and button name
    setSubject(`${serviceName}: ${buttonName}`);
    setIsSubjectEditable(false);  // Make the subject field uneditable
    
    // Scroll to the contact form section
    const targetElement = document.getElementById('contact-form');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    closeCardAfterTimeout();  // Ensure the timeout starts on page load
    return () => {
      if (timer) clearTimeout(timer);  // Cleanup the timeout on component unmount
    };
  }, [expandedService]);

  return (
    <div>
      <Header />
      <main>
        <div className="service-page">
          <div className="heroservice">
            <div className="heroservice-text">
              <h1>Build Safely with ELP.</h1>
              <p>Legal Safeguards go beyond protection.</p>
              <p>Commercialize and optimize your business with our services.</p>
            </div>
            <img src="/bus.webp" alt="Business Illustration" className="heroservice-image" />
          </div>

          {/* Services Section */}
          <section id="servicesExtend" className="services scroll-section">
            <h2>Specialties</h2>
            <div className="servicesExtend-cards">
              {servicesExtend.map(service => (
                <div key={service.id} className="servicesExtend-card">
                  <div className="service-card-header" onClick={() => handleServiceToggle(service.id)}>
                    <h3>{service.name}</h3>
                  </div>
                  {expandedService === service.id && (
                    <div className="service-card-details">
                      <p>{service.description}</p>

                      {/* Add the two buttons after the paragraph */}
                      <div className="service-card-buttons">
                        <button 
                          className="talk-button" 
                          onClick={() => handleButtonClick(service.name, "Talk to Understand Service")} // Passing service name and button name
                        >
                          Talk to Understand Service
                        </button>
                        <button 
                          className="quote-button" 
                          onClick={() => handleButtonClick(service.name, "Get a Quote")} // Passing service name and button name
                        >
                          Get a Quote
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </section>

          {/* Contact Section */}
          <section className="contact-section" id="contact-form">
            <div className="contact-left">
              <h1 className="contact-title">Let's chat.<br />Tell us about your business.</h1>
              <p className="contact-description">Let's secure together.</p>
            </div>
            <div className="contact-right">
              <h2 className="contact-form-title">Talk About: </h2>
              <form
                action="mailto:info@entrepreneuriallegalpartner.com"
                method="POST"
                encType="text/plain"
                className="contact-form"
              >
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={subject}  // Bind the subject input to the state
                  onChange={(e) => isSubjectEditable && setSubject(e.target.value)}  // Make subject editable only if it's allowed
                  disabled={!isSubjectEditable}  // Make the subject field uneditable if the state is false
                />
                <input type="text" name="name" placeholder="Full name*" required />
                <input type="email" name="email" placeholder="Email address*" required />

                <textarea name="message" placeholder="Please Provide more Information"></textarea>
                <input type="text" name="Company" placeholder="Company" />

                <button type="submit" className="contact-submit">Send message</button>
              </form>
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ServicePage;
