import React, { useState } from 'react';
import './ContactUs.css';
import Header from './header';
import Footer from './Footer';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    email: '',
    message: ''
  });

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission (without EmailJS)
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Your message has been sent!');  // Replace with your own form submission logic if needed
    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <div>
      <Header />
      <main>
        <section className="contact-options-section">
          <div className="contact-heading">
            <h1>Talk to Us</h1>
          </div>
          <div className="contact-cards">
            <div className="contact-option">
              <h2>Call Now</h2>
              <p>+254758715666</p>
            </div>
            <div className="contact-option">
              <h2>Email Us</h2>
              <p>info@entrepreneuriallegalpartner.com</p>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="contact-left">
            <h1 className="contact-title">Let's chat.<br />Tell us about your business.</h1>
            <p className="contact-description">Let's secure together.</p>
          </div>
          <div className="contact-right">
            <h2 className="contact-form-title">Send us a message</h2>
            <form onSubmit={handleSubmit} className="contact-form">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Full name*"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email address*"
                required
              />
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
              />
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us more about your project*"
                required
              ></textarea>
              <button type="submit" className="contact-submit">Send message</button>
            </form>
          </div>
        </section>

        {/* Connect Section */}
        <section className="connect-section">
          <h1>Connect with Us</h1>
          <div className="social-media-icons">
            <a href="https://www.linkedin.com/company/entrepreneuriallegalparner/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={30} />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={30} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter size={30} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={30} />
            </a>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ContactUs;
