import React, { useEffect } from "react";
import "./homepage.css";
import Header from './header'; // Adjust the path based on the location of your Header.js file
import Footer from './Footer'; // Import the Footer component
import ServicePage from './ServicePage';

import { Link } from "react-router-dom";
const womanImage = `${process.env.PUBLIC_URL}/WOMAN.webp`;

const scrollToServices = () => {
  const servicesSection = document.getElementById("services");
  if (servicesSection) {
    servicesSection.scrollIntoView({ behavior: "smooth" });
  }
};
// Function to expand the service section when clicked
function expandSection(e, serviceId) {
  e.preventDefault(); // Prevent default link behavior
  const section = document.getElementById(serviceId);

  // Scroll to the service section smoothly
  section.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });

  // Add class to expand the section
  section.classList.toggle('expanded'); // Toggle the 'expanded' class for visual expansion
  
  // Optionally, you can animate the content visibility using CSS or any other effect
}

// This can be added inside a React component or regular JavaScript depending on your setup.


const Homepage = () => {
  useEffect(() => {
    const sections = document.querySelectorAll(".scroll-section");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("scroll-visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div>
    <Header /> {/* Add the Header component */}
    <main>
    <div className="homepage">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <h1>Empowering Sustainable Entrepreneurship</h1>
            <p>We are taking startups legal and safeguarding businesses being impacted by technology.</p>
            <div className="hero-buttons">
              <button className="primary-button" onClick={scrollToServices}>
                Explore Services
              </button>
            </div>
          </div>
          <img src={womanImage} alt="BU Image" className="WOMAN-image" />
          <div className="hero-image">
            <div className="image-placeholder center-circle">
            </div>
          </div>
        </div>
      </section>

      {/* Action Items Section */}
      <section className="section-below-hero scroll-section">
        <div className="action-cards">

<div className="action-card-white">
  <h3>Request a Consultation</h3>
  <p>Talk to a legal consultant and get expert legal advice for your startup.</p>
  <Link to="/ContactUs" className="primary-button">
    Get Started
  </Link>
</div>

          <div className="action-card-black">
            <h3>Self-Service</h3>
            <p>Use our automated tools to make sound legal business decisions</p>
            <Link to="/teresya">
              <button className="action-card-black-button">Get Started</button>
            </Link>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="about scroll-section">
        <h2>About Us</h2>
        <p>
          With over a decade of combined experience across diverse fields, our team provides tailored legal solutions on business and technology. We empower startups, tech adopters, and digital creators through international and jurisdiction-specific legal guidance and tools.
        </p>
        <Link to="/AboutUs">
          <button className="learn-more">
            Learn More <span className="arrow">→</span>
          </button>
        </Link>
      </section>
{/* Services Section */}
<section id="services" className="services scroll-section">
  <h2>Specialties</h2>
  <div className="service-cards">
    <div className="service-card" id="business-incorporation">
      <h3>Business Incorporation</h3>
      <p>Guided decisions in corporate structuring, business registration, licensing, and permits.</p>
      <a href="/servicePage#servicesExtend">Learn More</a>
      </div>
    <div className="service-card" id="ip-protection">
      <h3>IP Protection</h3>
      <p>Make the most out of your intellectual property through legal protections and commercialization.</p>
      <a href="/servicePage#ip-protection">Learn More</a>
    </div>
    <div className="service-card" id="tech-compliance">
      <h3>Tech Compliance</h3>
      <p>Stay secure and compliant with services for tech development.</p>
      <a href="/servicePage#tech-compliance">Learn More</a>
    </div>
    <div className="service-card" id="international-expansion">
      <h3>International Expansion</h3>
      <p>Navigate global business expansion with expert legal support.</p>
      <a href="/servicePage#international-expansion">Learn More</a>
    </div>
    <div className="service-card" id="contracts-partnerships">
      <h3>Contracts & Partnerships</h3>
      <p>Receive contract review and negotiation guidance.</p>
      <a href="/servicePage#contracts-partnerships">Learn More</a>
    </div>
    <div className="service-card" id="data-privacy">
      <h3>Data Privacy</h3>
      <p>Ensure compliance with data privacy laws and cross-border transfer regulations.</p>
      <a href="/servicePage#data-privacy">Learn More</a>
    </div>
    <div className="service-card" id="investments-due-diligence">
      <h3>Investments & Due Diligence</h3>
      <p>Make informed decisions on secure and favorable investment structures.</p>
      <a href="/servicePage#investments-due-diligence">Learn More</a>
    </div>
    <div className="service-card" id="mergers-acquisitions">
      <h3>Mergers & Acquisitions</h3>
      <p>Expert guidance on mergers, acquisitions, and investor due diligence.</p>
      <a href="/servicePage#mergers-acquisitions">Learn More</a>
    </div>
  </div>
  <div className="service-button">

<Link to="/servicePage">
          <button className="primary-button">
            More Services
          </button>
        </Link>
  </div>
</section>


      {/* Placeholder for Footer */}
      <section className="footer-placeholder"></section>
    </div>
    <Footer /> {/* Add the Footer component */}

    </main>
    </div>
  );
};

export default Homepage;
