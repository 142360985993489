import React from 'react';
import './AboutUs.css';
import Header from './header'; // Adjust the path based on the location of your Header.js file
import Footer from './Footer';
import { Link } from 'react-router-dom'; // Ensure this import is added

// Roles array
const roles = [
  'Law Firms',
  'Seasonal Industry Lawyers',
  'Cybersecurity Analysts',
  'AI Engineers',
  'Exceptional Legal Researchers & Interns',
  'International Business Specialists',
  'Accounting and Tax Lawyers',
];

const AboutUs = () => {
  return (
    <div>
    <Header /> {/* Add the Header component */}
    <main>
    <div className="about-us-container">
      {/* Our Story Section */}
      <div className="our-story-section">
        <h1 className="section-title">Our Story</h1>
        <div className="our-story-content">
          <div className="story-text">
            <p>
              Entrepreneurial Legal Partner (ELP) was founded to empower entrepreneurs
              with ethical and protected business solutions. We are more than just a legal
              consultancy—we are a partner that embraces innovation and leverages technology
              to ease legal challenges. Our journey is dedicated to helping businesses thrive
              in a dynamic, technology-driven world.
            </p>
            <p>
              From self-service tools to expert-led guidance, we’ve built a bridge to connect
              entrepreneurs with legal solutions that drive economic success, one step at a time.
            </p>
          </div>
          <div className="story-image">
            {/* Add an image or other content here */}
          </div>
        </div>
      </div>

      {/* Our Expertise Section */}
      <section className="our-expertise">
        <h2 className="section-title">Our Expertise</h2>
        <div className="expertise-list">
          {roles.map((role, index) => (
            <div className="expertise-item" key={index}>
              <h3>{role}</h3>
            </div>
          ))}
        </div>
      </section>

      {/* Why Us Section */}
      <div className="why-us-section">
        <h2 className="section-title">Why Us?</h2>
        <div className="why-us-content">
          <h3>Our Vision, Your Success</h3>
          <p>
            At Entrepreneurial Legal Partner (ELP), we believe that every business, whether a startup
            or an established enterprise, deserves a trusted legal partner. We stand out because we
            blend innovation with experience, ensuring that our legal solutions are not just timely
            but also forward-thinking.
          </p>

          <Link to="/ContactUs" className="contact-us-button">
  Contact Us
</Link>
        </div>
      </div>

      {/* Our Initiatives Section */}
      <div className="our-initiatives-section">
        <h1 className="section-title">Our Initiatives</h1>

        {/* Lex & Latte Initiative */}
        <div className="initiative-card">
          <h2>Lex & Latte Educational Initiative</h2>
          <p>
            We believe in making legal resources accessible to everyone. The Lex & Latte initiative
            is our pro-bono program designed to empower communities and individuals with the legal
            knowledge they need to navigate business and law. Through workshops, webinars, and
            resource-sharing, we aim to build a legally conscious society.
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSc4xbe1upTY7j_5PcmkJeh0VVxVROrcJWvHBU_cmjo8QMN5Lg/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="initiative-link"
          >
            Join the Lex & Latte Initiative
          </a>
        </div>

        {/* ELP.Lab Initiative */}
        <div className="initiative-card">
          <h2>ELP.Lab</h2>
          <p>
            ELP.Lab is our research and development hub where innovation meets action.
            From AI-powered legal tools to cutting-edge solutions, ELP.Lab is the heart
            of our commitment to advancing entrepreneurship and legal technology.
          </p>
          <p>
            If you’re passionate about research, development, and working with transformative tools,
            express your interest and become a part of ELP.Lab:
          </p>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSfztUYw9nXJUP_HEni-xvT14RwhlWXkhU5cBcboqQuvyrkAQw/viewform"
            target="_blank"
            rel="noopener noreferrer"
            className="initiative-link"
          >
            Express Interest in ELP.Lab
          </a>
        </div>
      </div>
      </div>
      <Footer /> {/* Add the Footer component */}

      </main>

    </div>
  );
}

export default AboutUs;
